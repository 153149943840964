import React from "react"
import Instagram from '../images/instagram.svg'
import Twitter from '../images/twitter-square.svg'
import Email from '../images/email.svg'
import YouTube from '../images/youtube-square.svg'
import Spotify from '../images/spotify-square.svg'
import SoundCloud from '../images/soundcloud-square.svg'
import Facebook from '../images/facebook-square.svg'

function renderSocial() {
    return <div className="social-div">
    <a href={"https://open.spotify.com/artist/1LRolJj9QDXXL6olDwZlOA?si=j0vtXUehRN27gWBZUuugkw"}><img src={Spotify} alt="Spotify" className="social-icon" /></a>
    <a href={"https://soundcloud.com/naideia"}><img src={SoundCloud} alt="Soundcloud" className="social-icon" /></a>
    <a href={"https://twitter.com/naideia_music"}><img src={Twitter} alt="Twitter" className="social-icon" /></a>
    <a href={"https://www.instagram.com/naideia_music/"}><img src={Instagram} alt="Instagram" className="social-icon" /></a>
    <a href={"https://www.facebook.com/naideia.music"}><img src={Facebook} alt="Facebook" className="social-icon" /></a>
    <a href={"mailto:naideia.music@gmail.com"}><img src={Email} alt="Email" className="social-icon" /></a>
    <a href={"https://www.youtube.com/channel/UCc5Y_HaaOIUZKpjaZh9lHVQ"}><img src={YouTube} alt="YouTube" className="social-icon" /></a>
    </div>;
}

export default renderSocial;
