/*
Google Conversions: 

gtag_report_conversionA(url) = Logo click 

gtag_report_conversionB(url) = Album art click 

gtag_report_conversionC(url) = Button click 

 */

import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import Logo from "../components/logo"
import MusicPlayer from "../components/music-player"
import LinkedArt from '../images/linked-art.png'
import Social from "../components/social-links"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"

const SpotifyFollowPage = () => (
  <Layout>
  <Helmet>
  <title>Follow naideia on Spotify</title>
  <SEO title="Follow naideia on Spotify" />

<script>
  {`
    var cpm = {};
    (function(h,u,b){
    var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
    e.async=true;e.src='https://cookiehub.net/c2/9410b03d.js';
    e.onload=function(){u.cookiehub.load(b);}
    d.parentNode.insertBefore(e,d);
    })(document,window,cpm);
  `}
</script>

</Helmet>
  <div className="logo-container"><a className="listen-link-logo" href={"https://open.spotify.com/artist/1LRolJj9QDXXL6olDwZlOA?si=iwBPvuIkT-K6hkeVifK-DQ"}><Logo /></a></div>
  <h3 className="headline">Linked EP is Out Now!</h3>
    <div className="intro-section">
      <div className="album-promo">
      
        <div className="linked-art"><a className="listen-link-art" href={"https://open.spotify.com/artist/1LRolJj9QDXXL6olDwZlOA?si=iwBPvuIkT-K6hkeVifK-DQ"}><img src={LinkedArt} alt="Linked EP Album Art" /></a></div>
        <MusicPlayer />
      </div>

      <a className="listen-link" href={"https://open.spotify.com/artist/1LRolJj9QDXXL6olDwZlOA?si=iwBPvuIkT-K6hkeVifK-DQ"}><button className="service-button">Follow on Spotify</button></a>
      <p className="suggestion">Be sure to click the follow button on Spotify</p>

    </div>

    <footer>
      <Social />

      <p>© James Cole</p>
    </footer> 

  </Layout>
)

export default SpotifyFollowPage
